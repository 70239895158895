import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { Validators, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApiService, TranslatorService} from '../../service';
import { Config} from '../../guard';
import { LazyLoadEvent, ConfirmationService} from 'primeng/primeng'
import { MessageService} from 'primeng/api';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  title: any;
  @Input() displayProfile: boolean;
  @Input() Master: any;
  @Output() public closeProfile = new EventEmitter();
  form: FormGroup;
  setNamaLengkap: any;
  setEmail: any;
  setNoTelpon: any;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
		private messageService: MessageService,
		private terjemah: TranslatorService
  ) { }
  ngOnInit() {
    this.title = "PROFILE";
    this.form = this.fb.group({
			fullname: ['',Validators.required],
			email: [''],
			phone: ['']
    });

    this.Master = {
      fullname: '',
      email: '',
      phone: ''
    }
  }

  closePro() {
    this.closeProfile.emit(false);
  }

  saveProfile() {
    let dataKirim = this.form.value;
    this.apiService.add(Config.get().apiBackend + 'api/admin/edit-profile', dataKirim).subscribe(_res => {
        this.messageService.add({
            key: 't-notif',
            severity: 'success',
            summary: this.terjemah.getTranslate().sukses,
            detail: this.terjemah.getTranslate().pesanSuksesInputData
        });
        let userdata = JSON.parse(localStorage.getItem('user-profile'));
        userdata.fullname = dataKirim.fullname;
        userdata.phone = dataKirim.phone;
        userdata.email = dataKirim.email;
        let setUser = JSON.stringify(userdata);
        localStorage.setItem('user-profile',setUser);
        this.ngOnInit();
        this.closePro();

    });
  }

  ProfileShow(){
    let userdata = JSON.parse(localStorage.getItem('user-profile'));
    this.setNamaLengkap =  userdata.name;
    this.setEmail =  userdata.email;
    this.setNoTelpon =  userdata.phone;
  }

}
