import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent, NotFoundComponent, AccessDeniedComponent, LoginComponent,BiComponent} from './page';
import {AuthGuard, LoginGuard} from './guard';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [
        RouterModule.forRoot([
          {canActivate: [AuthGuard], path: '', component: DashboardComponent},
          {path: 'bi', component: BiComponent},
          {canActivate:[LoginGuard], path: 'login', component: LoginComponent},
          {canActivate:[AuthGuard], path: 'user', loadChildren: './page/master/user/user.module#UserModule'},
          {canActivate:[AuthGuard] ,path: 'kandang', loadChildren: './page/master/gudang/gudang.module#GudangModule' },
          {canActivate:[AuthGuard] ,path: 'ternak', loadChildren: './page/master/barang/barang.module#BarangModule' },
          {canActivate:[AuthGuard] ,path: 'pendataan-ternak', loadChildren: './page/master/barang2/barang.module#Barang2Module' },
          {canActivate:[AuthGuard] ,path: 'pemilik', loadChildren: './page/master/pemilik/pemilik.module#PemilikModule' },
          {canActivate:[AuthGuard] ,path: 'kelompok', loadChildren: './page/master/kelompok/kelompok.module#KelompokModule' },
          {canActivate:[AuthGuard] ,path: 'petugas', loadChildren: './page/master/petugas/petugas.module#PetugasModule' },
          {canActivate:[AuthGuard] ,path: 'map', loadChildren: './page/master/map/map.module#MapModule' },

          {canActivate:[AuthGuard] ,path: 'kode-straw', loadChildren: './page/master/kode-straw/kode-straw.module#KodeStrawModule' },
          {canActivate:[AuthGuard] ,path: 'pakan', loadChildren: './page/transaksi/pakan/pakan.module#PakanModule' },
          {canActivate:[AuthGuard] ,path: 'jenis-ternak', loadChildren: './page/master/jenis-ternak/jenis-ternak.module#JenisTernakModule' },
          {canActivate:[AuthGuard] ,path: 'jenis-vaksin', loadChildren: './page/master/jenis-vaksin/jenis-vaksin.module#JenisVaksinModule' },
          {canActivate:[AuthGuard] ,path: 'program', loadChildren: './page/master/program/program.module#ProgramModule' },
          {canActivate:[AuthGuard] ,path: 'rph', loadChildren: './page/master/rph/rph.module#RphModule' },
          {canActivate:[AuthGuard] ,path: 'rumpun', loadChildren: './page/master/rumpun/rumpun.module#RumpunModule' },
          {canActivate:[AuthGuard] ,path: 'vaksin-obat', loadChildren: './page/master/vaksin-obat/vaksin-obat.module#VaksinObatModule' },
          {canActivate:[AuthGuard] ,path: 'buku-induk', loadChildren: './page/transaksi/buku-induk/buku-induk.module#BukuIndukModule' },
          {canActivate:[AuthGuard] ,path: 'inseminasi', loadChildren: './page/transaksi/inseminasi/inseminasi.module#InseminasiModule' },
          {canActivate:[AuthGuard] ,path: 'pakan', loadChildren: './page/transaksi/pakan/pakan.module#PakanModule' },
          {canActivate:[AuthGuard] ,path: 'logging', loadChildren: './page/transaksi/logging/logging.module#LoggingModule' },
          {canActivate:[AuthGuard] ,path: 'mutasi', loadChildren: './page/transaksi/mutasi/mutasi.module#MutasiModule' },
          {canActivate:[AuthGuard] ,path: 'susu', loadChildren: './page/transaksi/susu/susu.module#SusuModule' },
          {canActivate:[AuthGuard] ,path: 'vaksinasi', loadChildren: './page/transaksi/vaksinasi/vaksinasi.module#VaksinasiModule' },
          {canActivate:[AuthGuard] ,path: 'berita', loadChildren: './page/informasi/berita/berita.module#BeritaModule' },
          {canActivate:[AuthGuard] ,path: 'kegiatan', loadChildren: './page/informasi/kegiatan/kegiatan.module#KegiatanModule' },
          {canActivate:[AuthGuard] ,path: 'broadcast', loadChildren: './page/informasi/broadcast/broadcast.module#BroadcastModule' },
          {canActivate:[AuthGuard] ,path: 'inbox', loadChildren: './page/informasi/inbox/inbox.module#InboxModule' },

          {canActivate:[AuthGuard] ,path: 'subtitusi', loadChildren: './page/transaksi/mutasi-eartag/subtitusi.module#SubtitusiModule' },
          {canActivate:[AuthGuard] ,path: 'pkb', loadChildren: './page/transaksi/pkb/pkb.module#PkbModule' },
          {canActivate:[AuthGuard] ,path: 'ib', loadChildren: './page/transaksi/ib/ib.module#IbModule' },
          {canActivate:[AuthGuard] ,path: 'upload-ternak', loadChildren: './page/master/update-ternak/update-ternak.module#UpdateTernakModule' },
          {canActivate:[AuthGuard] ,path: 'update-pemilik-kandang', loadChildren: './page/master/update-pemilik-kandang/update-ternak.module#UpdatePemilikKandangModule' },
          {canActivate:[AuthGuard] ,path: 'eartag-hilang', loadChildren: './page/master/eartag-hilang/eartag-hilang.module#EartagHilangModule' },
          {canActivate:[AuthGuard] ,path: 'embrio', loadChildren: './page/master/embrio/embrio.module#EmbrioModule' },
          {canActivate:[AuthGuard] ,path: 'role-menu', loadChildren: './page/setting/role-menu/role-menu.module#RoleMenuModule' },
          {canActivate:[AuthGuard] ,path: 'role-user', loadChildren: './page/setting/role-user/role-user.module#RoleUserModule' },
          {canActivate:[AuthGuard] ,path: 'menu', loadChildren: './page/setting/menu/menu.module#MenuModule' },
          {canActivate:[AuthGuard] ,path: 'upload-ib', loadChildren: './page/transaksi/upload-ib/upload-ib.module#UploadIbModule' },
          // 
          {path: 'not-found', component: NotFoundComponent},
          {path: 'access-denied', component: AccessDeniedComponent},
          // kalo gak ada page not found
          { path: '**', redirectTo: 'not-found' }
        ])
    ],
    exports: [RouterModule]
})
export class AppRoutes {}
