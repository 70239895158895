import {Component, AfterViewInit, OnDestroy, Renderer2} from '@angular/core';
import {Subscription} from 'rxjs'
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError} from '@angular/router';
  import {AuthService, TranslatorService, ApiService, LoaderService} from './service';
  import { TranslateService } from '@ngx-translate/core';
  import { MessageService } from 'primeng/api';
  import { Config } from './guard';

  enum MenuOrientation {
    STATIC,
    OVERLAY
  }

  @Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
  })
  export class AppComponent implements AfterViewInit, OnDestroy {

    activeTabIndex = -1;

    sidebarActive = false;

    layoutMode: MenuOrientation = MenuOrientation.STATIC;

    topbarMenuActive: boolean;

    overlayMenuActive: boolean;

    staticMenuDesktopInactive: boolean;

    staticMenuMobileActive: boolean;

    rotateMenuButton: boolean;

    sidebarClick: boolean;

    topbarItemClick: boolean;

    menuButtonClick: boolean;

    activeTopbarItem: any;

    documentClickListener: Function;

    isLogin: boolean;
    subscription : Subscription;
    theme = 'green';

    filteredMenu: any[]
    itemMenu: any;
    constructor(public renderer: Renderer2,
      public authService: AuthService,
      public router: Router,
      private translate: TranslateService,
      private terjemah: TranslatorService,
      private messageService: MessageService,
      private apiService: ApiService,
      private loaderService: LoaderService) {
        if (localStorage.getItem('lang')) {
          this.translate.use(localStorage.getItem('lang'))
        } else {
          this.translate.use('id')
        }
        if (localStorage.getItem('currentUser')){
          this.isLogin = true
          this.authService.setDataLogin(JSON.parse(localStorage.getItem('currentUser')))
        } else if (sessionStorage.getItem('currentUser')) {
          this.isLogin = true
          this.authService.setDataLogin(JSON.parse(sessionStorage.getItem('currentUser')))
        } else {
          this.isLogin = false
        }
        router.events.subscribe((event: RouterEvent) => {
          this.navigationInterceptor(event)
        })

        this.subscription = this.authService.getLoginStatus().subscribe(login => {this.isLogin = login})
        this.terjemah.generateTranslate()
      }
      navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
          this.loaderService.show()
        }
        if (event instanceof NavigationEnd) {
          this.loaderService.hide()
        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
          this.loaderService.hide()
        }
        if (event instanceof NavigationError) {
          this.loaderService.hide()
        }
      }
      ngAfterViewInit() {
        this.documentClickListener = this.renderer.listen('body', 'click', (event) => {
          if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
          }

          if (!this.menuButtonClick && !this.sidebarClick && (this.overlay || !this.isDesktop())) {
            this.sidebarActive = false;
          }

          this.topbarItemClick = false;
          this.sidebarClick = false;
          this.menuButtonClick = false;
        });
      }
      onTabClick(event: Event, index: number) {
        if (this.activeTabIndex === index) {
          this.sidebarActive = !this.sidebarActive;
        } else {
          this.activeTabIndex = index;
          this.sidebarActive = true;
        }

        event.preventDefault();
      }

      closeSidebar(event: Event) {
        this.sidebarActive = false;
        event.preventDefault();
      }

      onSidebarClick(event: Event) {
        this.sidebarClick = true;
      }

      onTopbarMenuButtonClick(event: Event) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;

        event.preventDefault();
      }

      onMenuButtonClick(event: Event, index: number) {
        this.menuButtonClick = true;
        this.rotateMenuButton = !this.rotateMenuButton;
        this.topbarMenuActive = false;
        this.sidebarActive = !this.sidebarActive;

        if (this.layoutMode === MenuOrientation.OVERLAY) {
          this.overlayMenuActive = !this.overlayMenuActive;
        } else {
          if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive; } else {
              this.staticMenuMobileActive = !this.staticMenuMobileActive; }
            }

            if (this.activeTabIndex < 0) {
              this.activeTabIndex = 0;
            }

            event.preventDefault();
          }

          onTopbarItemClick(event: Event, item) {
            this.topbarItemClick = true;

            if (this.activeTopbarItem === item) {
              this.activeTopbarItem = null; } else {
                this.activeTopbarItem = item; }

                event.preventDefault();
              }

              onTopbarSearchItemClick(event: Event) {
                this.topbarItemClick = true;

                event.preventDefault();
              }

              onTopbarSubItemClick(event) {
                event.preventDefault();
              }
              logout() {
                this.messageService.clear();
                this.messageService.add({key: 't-logout', sticky: true, severity:'warn', summary:this.terjemah.getTranslate().konfirmasiKeluar, detail:''});
              }
              get overlay(): boolean {
                return this.layoutMode === MenuOrientation.OVERLAY;
              }

              changeToStaticMenu() {
                this.layoutMode = MenuOrientation.STATIC;
              }

              changeToOverlayMenu() {
                this.layoutMode = MenuOrientation.OVERLAY;
              }

              isDesktop() {
                return window.innerWidth > 1024;
              }

              ngOnDestroy() {
                if (this.documentClickListener) {
                  this.documentClickListener();
                }
                this.subscription.unsubscribe();
              }
              onConfirm() {
                this.messageService.clear('t-logout');
                this.authService.logout()
              }
              onReject() {
                this.messageService.clear('t-logout');
              }
              filterMenu(event) {
                let query = event.query;
                let data = this.authService.getListRoute()
                // this.filteredMenu = this.filterMenuItem(query, data);
                this.filteredMenu = data.filter(route => {
                  return route.label.toLowerCase().includes(query.toLowerCase())
                })
              }
              filterMenuItem(query, data) {
                let filtered : any[] = [];
                for(let i = 0; i < data.length; i++) {
                  let dataItem = data[i];
                  if(dataItem.label.toLowerCase().includes(query.toLowerCase())) {
                    filtered.push(dataItem);
                  }
                }
                return filtered;
              }
              onSelectSearchMenu(event) {
                this.router.navigate(event.routerLink);
              }
            }
