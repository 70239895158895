import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
	HttpHandler,
	HttpEvent,
	HttpErrorResponse
} from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { TranslatorService } from './translator.service';
import {AuthService} from './auth.service';

@Injectable()
export class HeaderInterceptorService implements HttpInterceptor {
	constructor (
			public authService: AuthService,
			public messageService: MessageService,
			public translate: TranslatorService) {}
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let dataLoginUser: any
		let timeOut:string

		if (localStorage.getItem('currentUser')){
            dataLoginUser = JSON.parse(localStorage.getItem('currentUser'))
        } else if (sessionStorage.getItem('currentUser')) {
            dataLoginUser = JSON.parse(sessionStorage.getItem('currentUser'))
        } else {
            dataLoginUser = false
        }
		if (dataLoginUser) {
			req = req.clone({ headers: req.headers.set('Authorization','Bearer '+ dataLoginUser)});
		}
		
		if (!req.headers.has('Content-Type')) {
			if (req.url.includes('upload') || req.url.includes('import') || req.url.includes('information')) {
				// req = req.clone({ headers: req.headers.set('Content-Type', 'multipart/form-data') });
			} else {
				req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
			}
		}
		req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

		return next.handle(req).pipe(
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					console.log('event--->>>', event);
				}
				return event;
			}),
			catchError((error: HttpErrorResponse) => {
				let data = {};
				data = {
					reason: error && error.error.reason ? error.error.reason : '',
					status: error.status
				};
				if (error.status == 0){
					this.messageService.add({key: 't-notif', severity:'error', summary: this.translate.getTranslate().kesalahan, detail:this.translate.getTranslate().errorKoneksi});
				} else if (error.status == 401){
					this.authService.logout()
					this.messageService.add({key: 't-notif', severity:'error', summary: this.translate.getTranslate().kesalahan, detail: this.translate.getTranslate().pesanKesalahaSesiBerakhir});
				} else if (error.status == 500){
					this.messageService.add({key: 't-notif', severity:'error', summary: this.translate.getTranslate().kesalahan, detail: error.error.message});
				} else {
					this.messageService.add({key: 't-notif', severity:'error', summary: this.translate.getTranslate().kesalahan, detail: error.error.message});
				}

				return throwError(error);
			}))
	}
}
