import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService, TranslatorService} from '../../../service';
import { Config} from '../../../guard';
import { LazyLoadEvent, ConfirmationService} from 'primeng/primeng'
import { MessageService, Message} from 'primeng/api';
import { Validators, FormBuilder, FormGroup, FormArray, FormControl} from '@angular/forms';
import { Data } from '@angular/router';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize, catchError } from 'rxjs/operators';

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.css'],
	providers: [ConfirmationService]
})
export class UserComponent implements OnInit {
	title : any;
	dataTable: any[];
	totalRecords: number;
	loading: boolean;
	column: any = '';
	sort: any = '';
	page: number = Config.get().page;
	rows: number = Config.get().rows;
	selected: any;
	form: FormGroup;
	filter: FormGroup;
	cols: any[];
	colsError: any[];
	displayDialog: boolean = false;
	items: any[];
	home: string;
	dataRole: any[] = [];
	dataAreaKerja: any[] = [];
	d_province: any[];
	d_district: any[];
	d_sub_district: any[]
	d_village: any[]
	urlUpload: any;
	displayError: boolean = false;
	dataError : any[] = []
	msgs: Message[] = []
	panelFilter: boolean = true
	isEdit : boolean =false
	@ViewChild('fileUpload') fileUpload: any;
	constructor(private fb: FormBuilder,
		private apiService: ApiService,
		private messageService: MessageService,
		private terjemah: TranslatorService,
		private confirmationService: ConfirmationService,
		private http: HttpClient) { }
	ngOnInit() {
		this.loadAllCombo();
		this.title = 'Manajemen User';
		this.urlUpload = Config.get().apiBackend+'api/import/petugas';
		this.items = [
			{label:'Home', url: '#'},
			{label:'Manajemen User', url: '/user'}
		  ];
		this.form = this.fb.group({
			id : [''],
			nik : ['', Validators.required],
			id_role : ['', Validators.required],
			name : ['', Validators.required],
			email: [''],
			phone: [''],
			password: ['', Validators.required],
			id_province: [''],
			id_district: [''],
			id_sub_district: [''],
			id_village: [''],
			status: ''
			// areas: new FormArray([
			// 	new FormGroup({
			// 		work_area: new FormControl('')
			// 	})
			// ])
		})

		this.filter = this.fb.group({
			id : [''],
			nik : [''],
			id_role : [''],
			name : [''],
			email: [''],
			id_province: [''],
			id_district: [''],
			id_sub_district: [''],
			id_village: [''],
		})
		this.cols = [
			{header: "NIK", field: "username", width:"175px", align:"left", align_header: "center"},
			{header: "Nama", field: "name", width:"250px", align:"left", align_header: "center"},
			{header: "Telepon", field: "phone", width:"250px", align:"left", align_header: "center"},
			{header: "Email", field: "email", width:"175px", align:"left", align_header: "center"},
			{header: "Role User", field: "role_name", width:"175px", align:"left", align_header: "center"},
		]
	}
	
	cari(){
		this.getDataTable(this.column, this.sort, this.page, this.rows, this.filter.value)
	}

	setExpandCollapse($event){
		this.panelFilter = $event.collapsed
	}

	getDataTable(column,sort,page,rows,pencarian) {
		this.loading = true;
		var cari = '';
		if(pencarian){
			cari += '&nik='+pencarian.nik;
			cari += '&name='+pencarian.name;
			cari += '&email='+pencarian.email;
			cari += '&phone='+pencarian.phone;
			cari += '&id_role='+pencarian.id_role;
			cari += '&id_province='+pencarian.id_province;
			cari += '&id_district='+pencarian.id_district;
			cari += '&id_sub_district='+pencarian.id_sub_district;
			cari += '&id_village='+pencarian.id_village;
		}
		this.apiService.get(Config.get().apiBackend+'api/user?dir='+column+'&sort='+sort+'&page='+page+'&rows='+rows+cari).subscribe((res)=>{
			this.dataTable = res.data
			this.totalRecords = res.totalRow
			this.loading = false
		}, (_error: any) => {
				this.loading = false
		})
	}


	lazyLoad(event: LazyLoadEvent) {
		let sort
		if (event.sortOrder == 1) {
			sort = 'asc'
		} else {
			sort = 'desc'
		}
		if (event.sortField != undefined) {
			this.getDataTable(event.sortField,sort,event.first,event.rows,this.filter.value)
		}else{
			this.getDataTable('name',sort,event.first,event.rows,this.filter.value)
		}
	}
  
	onSubmit(){
		this.save();
		if (this.form.invalid) {
			console.log('testing');
				this.messageService.add({
						key: 't-notif',
						severity: 'warn',
						summary: this.terjemah.getTranslate().kesalahan,
						detail: this.terjemah.getTranslate().pesanKesalahanDataTidakLengkap
				});
		} else {
				this.save();
		}
	}

	batal(){
		this.displayDialog = false
		this.reset();
	}

	reset(){
		this.form.reset({
			id: '',
			nik : '',
			id_role :'',
			name : '',
			email: '',
			phone: '',
			password: '',
			id_province: '',
			id_district: '',
			id_sub_district: '',
			id_village: '',
			status: ''
		})
	}

	resetFilter(){
		this.filter.reset({
			id: '',
			nik : '',
			id_role :'',
			name : '',
			email: '',
			id_province: '',
			id_district: '',
			id_sub_district: '',
			id_village: '',
		})
	}

	resetPassword(data) {
		this.confirmationService.confirm({
			message: 'Apakah anda yakin akan mereset password?',
			accept: () => {
				let payload = {
					email: data.email
				}
				this.apiService.add(Config.get().apiBackend + '/api/forgot-password', payload).subscribe(_res => {
					this.messageService.add({
							key: 't-notif',
							severity: 'success',
							summary: this.terjemah.getTranslate().sukses,
							detail: this.terjemah.getTranslate().pesanSuksesInputData
					});
				});
			}
	});
	}

	save() {
		this.confirmationService.confirm({
			message: 'Simpan data?',
			accept: () => {
				let data = this.form.value;
				let payload = {
					id_profile: data.id,
					username: data.nik,
					name: data.name,
					email: (data.email)?data.email:'-',
					phone: (data.phone)?data.phone:'-',
					id_role: data.id_role,
					work_area: data.work_area,
					status: data.status,
					address:{
						id_province: data.id_province,
						id_district: data.id_district,
						id_sub_district: data.id_sub_district,
						id_village: data.id_village,
					}

				}
				if(!this.isEdit){
					payload['password'] = data.password;
				}
				this.apiService.add(Config.get().apiBackend + 'api/user/manage', payload).subscribe(_res => {
					this.messageService.add({
							key: 't-notif',
							severity: 'success',
							summary: this.terjemah.getTranslate().sukses,
							detail: this.terjemah.getTranslate().pesanSuksesInputData
					});
					this.isEdit = false
					this.displayDialog = false;
					this.getDataTable('','',0, 10,'')
				});
			}
		});
	}

	delete(data:any[]): void {
		this.confirmationService.confirm({
			message: 'Hapus data?',
			accept: () => {
				let dataKirim = data
				this.apiService.add(Config.get().apiBackend + 'api/admin/user/delete', dataKirim).subscribe(_res => {
					this.messageService.add({
							key: 't-notif',
							severity: 'success',
							summary: this.terjemah.getTranslate().sukses,
							detail: this.terjemah.getTranslate().pesanSuksesInputData
					});
					this.ngOnInit();
					this.displayDialog = false
					this.getDataTable('','',0, 10,'')
				});
			}
		});
	}

	
	get areas(): FormArray {
		return this.form.get('areas') as FormArray;
	  }

	addArea() {
		this.areas.push(
		  new FormGroup({
			district_id: new FormControl('')
		  })
		);
	}

	removeArea(index){
		this.areas.removeAt(index)
	}

	tambah(){
		this.isEdit = false
		this.comboDataProvince();
		this.reset()
		this.displayDialog = true
	}

	edit(event,data): void{
		this.isEdit = true
		this.comboDataProvince();
		let dataGrid = {
			id: data.id_profile,
			nik : data.username,
			id_role :data.id_role.toString(),
			name : data.name,
			email: data.email,
			password: '',
			phone: data.phone,
			id_province: data.id_province,
			id_district: data.id_district,
			id_sub_district: data.id_sub_district,
			id_village: data.id_village,
			status: data.status
		};
		this.comboDataDistrict(data.id_province);
		this.comboDataSubDistrict(data.id_district);
		this.comboDataVillage(data.id_sub_district);
		this.form.setValue(dataGrid);
		this.displayDialog = true
	}

	loadAllCombo(){
		this.comboDataProvince()
		this.comboDataRole();
	}

	async comboDataRole():Promise<void>{
		this.apiService.get(Config.get().apiBackend + 'api/admin/role-user/options').subscribe((res: any) => {
			this.dataRole = []
			res.data.forEach((data: any) => {
				this.dataRole.push({label: data.label, value: data.id})
			})
		})
	}

	async comboDataProvince():Promise<void>{
		this.apiService.get(Config.get().apiBackend + 'api/address/province?id_country=1').subscribe((res: any) => {
			this.d_province = []
			res.data.forEach((data: any) => {
				this.d_province.push({label: data.province, value: data.id})
			})
		})
	}

	async comboDataDistrict(id_province : any):Promise<void>{
		this.apiService.get(Config.get().apiBackend + 'api/address/district?id_province='+id_province).subscribe((res: any) => {
			this.d_district = []
			res.data.forEach((data: any) => {
				this.d_district.push({label: data.district, value: data.id})
			})
		})
	}
	async comboDataSubDistrict(id_district: any):Promise<void>{
		this.apiService.get(Config.get().apiBackend + 'api/admin/address/sub-district?id_district='+id_district).subscribe((res: any) => {
			this.d_sub_district = []
			res.data.forEach((data: any) => {
				this.d_sub_district.push({label: data.sub_district, value: data.id})
			})
		})
	}

	async comboDataVillage(id_sub_district: any):Promise<void>{
		this.apiService.get(Config.get().apiBackend + 'api/admin/address/village?id_sub_district=' + id_sub_district ).subscribe((res: any) => {
			this.d_village = []
			res.data.forEach((data: any) => {
				this.d_village.push({label: data.urban_village, value: data.id})
			})
		})
	}

	onChageProvince($event){
		this.comboDataDistrict($event.value);
	}

	onChageDistrict($event){
		this.comboDataSubDistrict($event.value);
	}

	onChageSubDistrict($event){
		this.comboDataVillage($event.value);
	}

	updateStatus(){
		this.confirmationService.confirm({
			message: 'Ubah status menjadi tidak aktif?',
			accept: () => {
				let pencarian = this.filter.value
			    let data = {
					 nik : pencarian.nik,
					 name : pencarian.name,
					 email : pencarian.email,
					 phone : pencarian.phone,
					 id_role : pencarian.id_role,
					 id_province : pencarian.id_province,
					 id_district : pencarian.id_district,
					 id_sub_district : pencarian.id_sub_district,
					 id_village : pencarian.id_village
				};
			  this.apiService.add(Config.get().apiBackend + 'api/user/status', data).subscribe(_res => {
				this.messageService.add({
				  key: 't-notif',
				  severity: 'success',
				  summary: this.terjemah.getTranslate().sukses,
				  detail: _res.message
				});
				this.getDataTable('','',0, 10,'')
			  });
			}
		  });
	}

}
