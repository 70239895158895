import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="footer clearfix">
        <span>Copyright Identik PKH, 2022</span>
    </div>
    `
})
export class AppFooterComponent {

}
