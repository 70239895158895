import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { Validators, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApiService, TranslatorService} from '../../service';
import { Config} from '../../guard';
import { LazyLoadEvent, ConfirmationService} from 'primeng/primeng'
import { MessageService} from 'primeng/api';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  title: any;
  @Input() displayPassword: boolean;
  @Input() Master: any;
  @Input() Items: any;
  @Output() public closePassword = new EventEmitter();
  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
		private messageService: MessageService,
		private terjemah: TranslatorService
  ) { }
  ngOnInit() {
    this.title = "CHANGE PASSWORD";
    this.form = this.fb.group({
      password: ['', Validators.required],
      confirm_password : ['', Validators.required],
    });
  }

  close() {
    this.closePassword.emit(false);
  }

  savePassword() {
    let dataKirim = this.form.value;
    this.apiService.add(Config.get().apiBackend + 'api/admin/change-password', dataKirim).subscribe(_res => {
        this.messageService.add({
            key: 't-notif',
            severity: 'success',
            summary: this.terjemah.getTranslate().sukses,
            detail: this.terjemah.getTranslate().pesanSuksesInputData
        });
        this.ngOnInit();
        this.close();

    });
  }

}
